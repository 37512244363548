import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Panel Manufacturing Plant' },
    { img: data.i2, caption: 'Panel Manufacturing Plant' },
    { img: data.i3, caption: 'Wall Panels on Dropped Trailer' },
    { img: data.i4, caption: 'Wall Panel Delivery' },
    { img: data.i5, caption: 'Flying a Wall Panel into place' },
    { img: data.i6, caption: 'Flying a Wall Panel into Place' },
    { img: data.i7, caption: 'Shooting Wall Panels down' },
    { img: data.i8, caption: 'Braced Wall Panels' },
    { img: data.i9, caption: 'Insulating Metal Stud demising Wall' },
  ];

  return (
    <Layout>
      <SEO title="System Components: Metal Stud Wall Panels" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components</h2>
        <h1 className='title title--xxxl color-primary'>Metal Stud Wall Panels</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <h3 className="subtitle subtitle--md color-primary type-bold">
            Pre-Panelized Load-Bearing Metal Stud Walls
          </h3>

          <p>A key ingredient in the success of The Infinity Structural System is the pre-panelized
          load-bearing metal stud walls. The wall panels are pre-fabricated off-site in a
          panelization plant in advance and delivered to the job site when needed.  Generally,
          approximately 50% of the walls in a building will be load-bearing metal stud panels,
          with the other 50% being non-bearing “stick-built” metal stud partitions.</p>

          <p>Infinity’s metal stud wall panels are fabricated with welded connections and very close
          tolerances for the highest quality available (using wire-feed welders, with the welds
          painted afterwards.)  We have continually expanded and improved our metal stud wall panel
          manufacturing capabilities to ensure prompt, on-time deliveries with state-of-the-art
          Quality Control.   Our metal studs are ordered to very tight tolerances and our panel jigs
          are constructed with compressive mechanisms to fully seat the studs in the track prior
          to welding the connections.  Unlike curtain wall or non-bearing metal stud framing, it
          is critical to get the load-bearing stud fully seated and squarely in the track so that
          the axial design loads will be transmitted directly from the stud to the floor slab.</p>

          <p>Unfortunately, many times a drywall/metal framing sub will try to convince the
          General Contractor that he can “stick-build” the load-bearing metal stud walls (using
          screws) cheaper and just as fast as if they had used Infinity’s panels.  Invariably
          when this happens, the project becomes a disaster and does not at all represent “The
          Infinity Structural System.”  The construction schedule gets delayed and the load-bearing
          metal stud walls are a mess.  There are usually many gaps between the studs and track,
          improperly installed lateral bridging (if any), improperly installed X-strapping, missing
          or improperly aligned stud columns where point loads need to track down the building and
          possibly the wrong type or gage of studs.   These problems do not occur with Infinity
          Structures due to our rigorous pre-planning and dissection of the drawings in advance,
          use of PanelCAD to detail the wall panels, and our tight QC in the fabrication plant.</p>

          <p>The load-bearing metal studs are 3 5/8” or 6” galvanized studs
          spaced at 12’ or 16” on center.   The gage of the studs ranges from
          18, 16, 14 or 12-gage depending upon the loads.  The load-bearing
          studs come in flange widths of 1 5/8”, 2” and 2.5“, 3”, and 3.5”.</p>

          <p>The load-bearing metal studs may be custom ordered in any length to
          achieve the desired floor-to-floor and ceiling heights.  The studs have
          pre-punched holes for electrical as well as for CRC lateral bridging.</p>

          <p>The load-bearing metal stud walls achieve 1-hour or 2-hour U.L.
          fire ratings depending upon how many layers of drywall are used.</p>

          <p>Please contact Infinity Structures for more information on design,
          sections, details, U.L. ratings, and STC ratings. We will also be happy
          to work closely with your design team providing preliminary layouts and
          guidance on the most economical approach for your specific project.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/01-Panel060_(40682).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/02-Panel030_(40679).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/03-WallP190_(33962).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/04-WallP040_(34259).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/05-CityP010_(33891).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/06-WallP140_(40616).png" }) {
            ...GalleryCarouselImageFragment },
        i7: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/07-WallP180_(33905).png" }) {
            ...GalleryCarouselImageFragment },
        i8: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/08-WallP120_(40614).png" }) {
            ...GalleryCarouselImageFragment },
        i9: file(relativePath: { eq: "system-components/metal-stud-wall-panels/gallery/09-WallP300_(43045).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
